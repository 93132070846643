h1{
	font-weight: 350 !important;
}

h2{
	font-weight: 350 !important;
}
h3{
	font-weight: 350 !important;
}

h4{
	font-weight: 350 !important;
}

h5 {
	font-weight: 350 !important;
}